import { memo } from 'react';

type GraphWeightSvgProps = {
  initialWeight: number;
  goalWeight: number;
  cercleDuration: string;
  totalDuration: string;
};

type GraphLabelProps = {
  label: string;
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
};

const GraphLabel = ({ label, top, left, right, bottom }: GraphLabelProps) => (
  <div
    className="font-semibold text-p2 leading-5 absolute"
    style={{
      top,
      left,
      right,
      bottom,
    }}
  >
    <span className="bg-teal-500 rounded-md p-[6px] text-grey-900 rounded-bl-none">
      {label}
    </span>
  </div>
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GraphSvg = ({
  initialWeight,
  goalWeight,
  totalDuration,
  cercleDuration,
}: GraphWeightSvgProps) => {
  return (
    <div className="relative">
      <GraphLabel label={`${initialWeight} kg`} top="7.5%" left="6%" />
      <GraphLabel label={`${goalWeight} kg`} top="47.5%" left="55%" />
      <svg viewBox="0 0 361 251" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line
          x1="13.4688"
          y1="39.9259"
          x2="352.619"
          y2="39.9259"
          stroke="#313232"
          strokeWidth="2.23606"
        />
        <line
          x1="13.469"
          y1="197.168"
          x2="352.62"
          y2="197.168"
          stroke="#313232"
          strokeWidth="2.23606"
        />
        <path
          d="M87.4569 84.9652C56.4838 80.5563 22.9255 58.8097 10.1152 43.7944V197.822H343.982V184.26C247.521 190.072 196.184 164.401 182.573 150.839C161.596 134.209 128.29 90.7776 87.4569 84.9652Z"
          fill="#D3F7F0"
          fillOpacity="0.2"
        />

        <path
          d="M185.936 156.163C235.415 184.74 289.699 184.74 343.502 184.74"
          stroke="#3EC7AE"
          strokeWidth="3.35409"
          strokeDasharray="8.94 8.94"
        />
        <path
          d="M10.1152 43.7944C22.9255 58.8097 56.6559 79.4657 87.4569 84.9652C133.574 93.1994 161.596 134.209 182.573 150.839"
          stroke="#3EC7AE"
          strokeWidth="4.47212"
        />
        <ellipse
          cx="346.358"
          cy="183.757"
          rx="6.69972"
          ry="6.74233"
          fill="#3EC7AE"
        />
        <ellipse
          cx="12.0588"
          cy="43.9113"
          rx="6.70817"
          ry="6.74233"
          fill="#3EC7AE"
        />
        <line
          x1="182.249"
          y1="36.0444"
          x2="182.249"
          y2="194.915"
          stroke="#E7E7E7"
          strokeWidth="2.23606"
          strokeDasharray="6.71 6.71"
        />
        <path
          d="M194.616 151.227C194.616 158.068 189.107 163.596 182.335 163.596C175.563 163.596 170.054 158.068 170.054 151.227C170.054 144.385 175.563 138.857 182.335 138.857C189.107 138.857 194.616 144.385 194.616 151.227Z"
          fill="#3EC7AE"
          stroke="#B2E8DF"
          strokeWidth="3.35409"
        />
        <text
          fill="#E7E7E7"
          xmlSpace="preserve"
          style={{ whiteSpace: 'pre' }}
          fontFamily="Maison Neue"
          fontSize="14"
          letterSpacing="0.01em"
        >
          <tspan x="160.97" y="221.443">
            {cercleDuration}&#10;
          </tspan>
        </text>
        <text
          fill="#E7E7E7"
          xmlSpace="preserve"
          style={{ whiteSpace: 'pre' }}
          fontFamily="Maison Neue"
          fontSize="14"
          letterSpacing="0.01em"
        >
          <tspan x="306.783" y="221.443">
            {totalDuration}
          </tspan>
        </text>
        <text
          fill="#E7E7E7"
          xmlSpace="preserve"
          style={{ whiteSpace: 'pre' }}
          fontFamily="Maison Neue"
          fontSize="14"
          letterSpacing="0.01em"
        >
          <tspan x="9.82239" y="221.184">
            {'Aujourd'}&#x2019;{'hui'}
          </tspan>
        </text>
      </svg>
    </div>
  );
};

export const GraphWeightSvg = memo(GraphSvg);
