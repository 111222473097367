import { t } from '@lingui/macro';
import { marked } from 'marked';
import type { FunctionComponent } from 'react';
import { Typography } from '../../../shared/view/components/Typography/Typography';
import { useWeightLossEstimation } from '../shared/infra/useWeightLossEstimation';
import { BackHeader } from '../shared/view/BackHeader';
import { GraphWeight } from '../shared/view/GraphWeight/GraphWeight';
import { ScreenContentContainer } from '../shared/view/ScreenContentContainer';
import { StickyFooter } from '../shared/view/StickyFooter';

export const Graphic: FunctionComponent = () => {
  const { weightLossEstimation, molecule } = useWeightLossEstimation();

  if (!weightLossEstimation) {
    return null;
  }

  const duration = molecule
    ? t`graphics.molecule.duration`
    : t`graphics.default.duration`;
  const title = t`graphics.loss.title ${weightLossEstimation} ${duration}`;

  return (
    <ScreenContentContainer>
      <div className="w-full flex flex-col flex-1 pb-[80px]">
        <BackHeader />
        <div className="h-4" />

        <Typography.Title className="w-full">
          <span
            dangerouslySetInnerHTML={{
              __html: marked.parse(title),
            }}
          />
        </Typography.Title>
        <div className="h-32" />
        <div className="flex flex-col h-[75%] max-h-[600px] justify-center">
          <div className="-mx-8">
            <GraphWeight />
          </div>
          <div className="h-48" />
          <div className="bg-rainbow-row rounded-xl flex p-2">
            <div className="rounded-xl grow bg-grey-800 p-24">
              <Typography.P1 className="font-sans-ext font-semibold text-title-s leading-5">
                <span
                  className="leading-6"
                  dangerouslySetInnerHTML={{
                    __html: marked.parse(t`graphics.tagline`),
                  }}
                />
              </Typography.P1>
            </div>
          </div>
        </div>
      </div>

      <StickyFooter
        nextButtonProps={{
          label: t`graphics.cta`,
        }}
      />
    </ScreenContentContainer>
  );
};
