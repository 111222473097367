import { useQuery } from '@apollo/client';
import type {
  GetPriceQuery,
  GetPriceQueryVariables,
} from '../../../../shared/infra/queries.types';
import { useOnboardingStore } from '../../shared/domain/onboarding.store';
import { GET_PRICE_QUERY } from './getPrice.query';

export const useGetPrice = () => {
  const protocolId = useOnboardingStore((state) => state.protocolId);

  const { data, loading, error, refetch } = useQuery<
    GetPriceQuery,
    GetPriceQueryVariables
  >(GET_PRICE_QUERY, {
    variables: {
      protocolId: protocolId ?? '',
    },
    skip: !protocolId,
  });

  if (data?.protocol) {
    const oneOffPrice = data.protocol.price;
    const installmentCount = 12;
    const installmentPrice = data.protocol.installmentPrice;
    const installmentTotalPrice = installmentPrice * installmentCount;
    const discountOneOffOverInstallment = Math.floor(
      (100 * (installmentTotalPrice - oneOffPrice)) / installmentTotalPrice,
    );

    return {
      discountOneOffOverInstallment,
      price: oneOffPrice,
      initialSuccessFee: data.protocol.initialSuccessFee,
      installmentFrequency: data.protocol.installmentFrequency,
      installmentPrice,
      installmentCount,
      installmentTotalPrice,
      loading,
      error,
      refetch,
    };
  } else {
    return {
      discountOneOffOverInstallment: 0,
      price: 0,
      initialSuccessFee: 0,
      installmentPrice: 0,
      installmentFrequency: 7,
      installmentCount: 8,
      installmentTotalPrice: 0,
      loading,
      error,
      refetch,
    };
  }
};
