export const adaptPrice = (price: number | undefined): string => {
  return displayPrice((price ?? 0) / 100);
};

export const displayPrice = (price: number): string => {
  return `${price}€`;
};

export const adaptPriceFrench = (price: number | undefined): string => {
  return frenchPrice((price ?? 0) / 100);
};

export const frenchPrice = (price: number | string): string => {
  return `${String(price).replace('.', ',')}€`;
};
