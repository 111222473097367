import { useOnboardingStore } from '../domain/onboarding.store';
import { MOUNJARO, SAXENDA, WEGOVY } from '../../../../shared/constants';

const weightLossFromBmi = (bmi: number | null) => {
  if (bmi == null) return null;
  if (bmi < 25) return 4;
  if (bmi < 30) return 6;
  if (bmi < 40) return 8;
  return 10;
};

const weightLossFromMolecule = (molecule: string, initialWeight: number) => {
  if (molecule === SAXENDA) return Math.round(initialWeight * 0.08);
  if (molecule === WEGOVY || molecule === MOUNJARO)
    return Math.round(initialWeight * 0.18);
  return 0;
};

export const computeBMI = (initialWeight: number, initialHeight: number) => {
  if (!initialWeight || !initialHeight) return null;
  const heightInMeter = initialHeight / 100;
  return initialWeight / (heightInMeter * heightInMeter);
};

export const useWeightLossEstimation = () => {
  const { medicalSurveyAnswers, molecule } = useOnboardingStore(
    (store) => store,
  );

  if (!medicalSurveyAnswers) {
    return {
      initialHeight: 0,
      initialWeight: 0,
      bmi: null,
      weightLossEstimation: null,
      molecule: null,
    };
  }

  const initialWeight: number = parseFloat(
    parseFloat(medicalSurveyAnswers.weight).toFixed(1),
  );
  const initialHeight: number = parseFloat(medicalSurveyAnswers.height);
  const bmi = computeBMI(initialWeight, initialHeight);
  const weightLossEstimation = molecule
    ? weightLossFromMolecule(molecule, initialWeight)
    : weightLossFromBmi(bmi);

  return { initialWeight, initialHeight, weightLossEstimation, bmi, molecule };
};
