import { useWeightLossEstimation } from '../../infra/useWeightLossEstimation';
import { GraphWeightSvg } from './GraphWeightSvg';
import { t } from '@lingui/macro';

export const GraphWeight = () => {
  const { initialWeight, weightLossEstimation, molecule } =
    useWeightLossEstimation();

  if (!weightLossEstimation) {
    return null;
  }

  const goalWeight = initialWeight - weightLossEstimation;
  const duration = molecule
    ? t`graphics.molecule.duration`
    : t`graphics.default.duration`;

  const totalDuration = molecule
    ? t`graphics.molecule.totalDuration`
    : t`graphics.default.totalDuration`;

  return (
    <div data-testid="graph-svg">
      <GraphWeightSvg
        initialWeight={initialWeight}
        goalWeight={goalWeight}
        cercleDuration={duration}
        totalDuration={totalDuration}
      />
    </div>
  );
};
