import { t } from '@lingui/macro';
import { useState } from 'react';
import Cocaul from '../../../shared/view/images/cocaul.png';
// import Gilbert from '../../../shared/view/images/gilbert.png';
import Sokol from '../../../shared/view/images/sokol.png';
import { Typography } from '../../../shared/view/components/Typography/Typography';
import ReactCardFlip from 'react-card-flip';
import { FlipRightIcon } from '../../../shared/view/icons/flipCard/FlipRight.icon';
import { FlipLeftIcon } from '../../../shared/view/icons/flipCard/FlipLeft.icon';
import Screen from '../../../shared/view/images/screen.png';
import Graduation from '../../../shared/view/images/graduation.png';
import Book from '../../../shared/view/images/book.png';
import Resources from '../../../shared/view/images/resources.png';
import TimeCircle from '../../../shared/view/images/time-circle.png';
import Coach from '../../../shared/view/images/coach.png';
import Progress from '../../../shared/view/images/progress.png';
import { OnboardingTemplateScreen } from '../../../modules/Onboarding/shared/view/OnboardingTemplateScreen';

type Props = {
  onSubmit?: () => Promise<void>;
};

const expertSectionData = [
  {
    number: 1,
    name: t`experts.coco.name`,
    image: Cocaul,
    description: [
      {
        icon: Graduation,
        text: t`experts.coco.presentation.1`,
        alt: 'graduation',
      },
      { icon: Book, text: t`experts.gigi.presentation.2`, alt: 'book' },
      {
        icon: Screen,
        text: t`experts.coco.presentation.3`,
        alt: 'screen',
      },
    ],
    gradientClass: 'bg-gradient-to-b from-blue-500 to-blue-200',
  },
  {
    number: 2,
    name: t`experts.sokol.name`,
    image: Sokol,
    description: [
      {
        icon: Graduation,
        text: t`experts.sokol.presentation.1`,
        alt: 'graduation',
      },
      { icon: Book, text: t`experts.sokol.presentation.2`, alt: 'book' },
      {
        icon: Screen,
        text: t`experts.sokol.presentation.3`,
        alt: 'screen',
      },
    ],
    gradientClass: 'bg-gradient-to-b from-orange-500 to-orange-200',
  },
];

const presentationListItems = [
  t`program.presentation.3.description.1`,
  t`program.presentation.3.description.2`,
  t`program.presentation.3.description.3`,
  t`program.presentation.3.description.4`,
];

const ProgramPresentationComponent = () => {
  return (
    <>
      <div className="flex space-x-4 w-full pb-8 uppercase">
        <div className="flex flex-col justify-between bg-teal-500 rounded-xl w-1/2 px-24 pt-24">
          <div className="">
            <Typography.P4 className="flex text-grey-900 font-bold">
              {t`program.presentation.3.title`}
            </Typography.P4>
            <ul className="pl-16 pt-8 list-disc">
              {presentationListItems.map((item, index) => (
                <li key={index}>
                  <Typography.P4 className="flex text-grey-900">
                    {item}
                  </Typography.P4>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex justify-end">
            <img src={Coach} alt="resources" className="w-1/2" />
          </div>
        </div>
        <div className="flex flex-col justify-between bg-grey-600 rounded-xl w-1/2 items-center pt-8">
          <div className="pb-4">
            <Typography.P4 className="text-grey-0 font-bold">
              {t`program.presentation.1.title`}
            </Typography.P4>
          </div>
          <img src={Resources} alt="resources" className="w-2/3" />
        </div>
      </div>
      <div className="flex space-x-4 w-full max-h-fit uppercase">
        <div className="flex flex-row space-x-32 items-center bg-orange-200 rounded-xl w-1/2 py-16 px-12">
          <div className="w-1/3">
            <Typography.P4 className="flex items-center text-center text-grey-900 font-bold">
              {t`program.presentation.4.title`}
            </Typography.P4>
          </div>
          <div className="w-2/3">
            <img src={Progress} alt="resources" className="w-4/5" />
          </div>
        </div>
        <div className="flex flex-col bg-blue-600 rounded-xl w-1/2 items-center py-24">
          <img src={TimeCircle} alt="resources" className="w-2/3" />
          <Typography.P4 className="text-grey-0 font-bold pt-16">
            {t`program.presentation.2.title`}
          </Typography.P4>
        </div>
      </div>
    </>
  );
};

export const ProgramSummaryContent = ({ onSubmit }: Props) => {
  const [flippedCards, setFlippedCards] = useState<number[]>([]);

  const flipCard = (index: number) => {
    if (flippedCards.includes(index)) {
      setFlippedCards(flippedCards.filter((card) => card !== index));
    } else {
      setFlippedCards([...flippedCards, index]);
    }
  };

  const FrontComponent = ({
    index,
    image,
    name,
  }: {
    index: number;
    image: string;
    name: string;
  }) => {
    return (
      <div className="flex">
        <div>
          <img src={image} alt={name} />
        </div>
        <div className="absolute w-full py-2.5 top-0 inset-x-0">
          <div className="flex pl-12 pr-4 py-4">
            <div className="w-5/6">
              <Typography.P4 className="uppercase font-bold">
                {name}
              </Typography.P4>
            </div>
            <div
              onClick={() => flipCard(index)}
              role="button"
              className="flex justify-end w-1/6 h-16"
            >
              <FlipRightIcon />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const BackComponent = ({
    index,
    name,
    gradientClass,
    description,
  }: {
    index: number;
    name: string;
    gradientClass: string;
    description: { icon: string; alt: string; text: string }[];
  }) => {
    return (
      <div className="aspect-w-16 aspect-h-16">
        <div
          className={`flex flex-col justify-between overflow-y-auto h-full uppercase ${gradientClass}`}
        >
          <div className="flex pl-12 pr-4 py-4">
            <div className="w-5/6 leading-tight">
              <Typography.P4 className="font-bold text-grey-900">
                {name}
              </Typography.P4>
            </div>
            <div
              onClick={() => flipCard(index)}
              role="button"
              className="flex justify-end w-1/6 h-16"
            >
              <FlipLeftIcon />
            </div>
          </div>
          {description.map((item, index) => (
            <div
              key={index}
              className="flex space-x-12 px-16 py-4 items-center"
            >
              <img src={item.icon} alt={item.alt} className="w-16" />
              <Typography.P5 className="text-grey-900">
                {item.text}
              </Typography.P5>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <OnboardingTemplateScreen
      title={t`plan.summary.title`}
      ctaLabel={t`cta.next`}
      progressBar={{
        label: t`method.progress-bar`,
        currentStep: 2,
        maxStep: 6,
      }}
      onPress={onSubmit}
    >
      <ProgramPresentationComponent />
      <div className="h-16" />
      <Typography.P1 className="font-sans-ext font-semibold text-center">{t`experts.section`}</Typography.P1>
      <div className="h-16" />
      <div className="container mx-auto p-6">
        <div className="flex items-stretch">
          {expertSectionData.map((item, index) => (
            <div key={index} className="flex-1">
              <ReactCardFlip
                isFlipped={flippedCards.includes(item.number)}
                flipDirection="horizontal"
              >
                <FrontComponent
                  index={item.number}
                  image={item.image}
                  name={item.name}
                />
                <BackComponent
                  index={item.number}
                  name={item.name}
                  gradientClass={item.gradientClass}
                  description={item.description}
                />
              </ReactCardFlip>
            </div>
          ))}
        </div>
      </div>
    </OnboardingTemplateScreen>
  );
};
