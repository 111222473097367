import { t } from '@lingui/macro';
import { ErrorPage } from '../../../shared/view/components/ErrorPage/ErrorPage';
import { LoadingView } from '../../../shared/view/components/LoadingView/LoadingView';
import { useWorkflowNavigation } from '../../../shared/view/components/Workflow';
import { useOnboardingStore } from '../shared/domain/onboarding.store';
import { OnboardingTemplateScreen } from '../shared/view/OnboardingTemplateScreen';
import type { ProtocolCategory } from './domain/ProtocolCategory.type';
import { useGetProblems } from './infra/useGetProblems';
import { ProblemItem } from './view/ProblemItem';

export const SelectProblemScreen = () => {
  const { navigation } = useWorkflowNavigation();
  const { data, loading, error, refetch } = useGetProblems();
  const { setOnboardingAnswers, protocolCategoryId } = useOnboardingStore(
    (store) => ({
      setOnboardingAnswers: store.setOnboardingAnswers,
      protocolCategoryId: store.protocolCategoryId,
    }),
  );

  const screenTitle = t`Merci, quel problème souhaitez-vous régler ?`;

  if (
    error ||
    (!loading && !data) ||
    (data && data.protocolCategories.length === 0)
  ) {
    return <ErrorPage error={error} onRetry={refetch} isRetrying={loading} />;
  }

  if (loading || data === undefined) {
    return (
      <OnboardingTemplateScreen title={screenTitle}>
        <LoadingView isLoading={loading} />
      </OnboardingTemplateScreen>
    );
  }

  if (data.protocolCategories.length === 1) {
    const category = data.protocolCategories[0];
    if (category) {
      setOnboardingAnswers({
        protocolCategoryId: category.id,
        protocolId: category.protocols[0].id,
        protocolName: category.protocols[0].title,
      });
      navigation.navigate('selectsubproblem');
    }
  }

  const onProblemItemClicked = (category: ProtocolCategory) => {
    if (category.protocols.length === 1) {
      // We can navigate directly to survey because there is only one protocol in this category. No need to pick a subcategory.
      setOnboardingAnswers({
        protocolCategoryId: category.id,
        protocolId: category.protocols[0].id,
        protocolName: category.protocols[0].title,
      });
      navigation.navigate('survey');
      return;
    }
    if (category.protocols.length === 0) {
      // Should not happen as the back should return at least one protocol per category.
      setOnboardingAnswers({
        protocolCategoryId: category.id,
      });
      navigation.navigate('survey');
      return;
    }

    setOnboardingAnswers({
      protocolCategoryId: category.id,
    });
    navigation.goNext();
  };

  return (
    <OnboardingTemplateScreen title={screenTitle}>
      {data.protocolCategories.map((category) => (
        <div key={category.title} className="w-full">
          <ProblemItem
            className={`${
              category.id === protocolCategoryId ? 'bg-grey-700' : 'bg-grey-800'
            } `}
            onClick={() => onProblemItemClicked(category)}
            label={category.title}
          />
          <div className="h-8" />
        </div>
      ))}
    </OnboardingTemplateScreen>
  );
};
