import { t } from '@lingui/macro';
import { useEffect } from 'react';
import { ErrorPage } from '../../../shared/view/components/ErrorPage/ErrorPage';
import { LoadingView } from '../../../shared/view/components/LoadingView/LoadingView';
import { useWorkflowNavigation } from '../../../shared/view/components/Workflow';
import { useOnboardingStore } from '../shared/domain/onboarding.store';
import { OnboardingTemplateScreen } from '../shared/view/OnboardingTemplateScreen';
import { filterProtocolCategoriesById } from './domain/filterProtocols';
import { useGetProblems } from './infra/useGetProblems';
import { ProblemItem } from './view/ProblemItem';
import { capitalize } from 'lodash';
import { MOUNJARO, SAXENDA, WEGOVY } from '../../../shared/constants';

const subCategoryTitle = (molecule: string | null | undefined) => {
  if (molecule) return capitalize(`${molecule}`);
  return t`selectsubproblem.answer.weight_loss`;
};

export const SelectSubProblemScreen = () => {
  const { data, loading, error, refetch } = useGetProblems();
  const { navigation } = useWorkflowNavigation();
  const { setOnboardingAnswers, protocolCategoryId, protocolId } =
    useOnboardingStore((store) => ({
      setOnboardingAnswers: store.setOnboardingAnswers,
      protocolCategoryId: store.protocolCategoryId,
      protocolId: store.protocolId,
    }));

  useEffect(() => {
    if (loading || error || data === undefined) return;
    const subprotocols = filterProtocolCategoriesById(
      data.protocolCategories,
      protocolCategoryId,
    );
    if (subprotocols === undefined) {
      navigation.goPrevious();
    }
  }, [loading, error, navigation, data, protocolCategoryId]);

  if (error || (!loading && !data)) {
    return <ErrorPage error={error} onRetry={refetch} isRetrying={loading} />;
  }
  if (loading || !data) {
    return (
      <OnboardingTemplateScreen title={t`selectsubproblem.title`}>
        <LoadingView isLoading={loading} />
      </OnboardingTemplateScreen>
    );
  }

  const subprotocols = filterProtocolCategoriesById(
    data.protocolCategories,
    protocolCategoryId,
  );

  const onProblemItemClicked = (
    id: string,
    title: string,
    molecule: string | undefined | null,
  ) => {
    setOnboardingAnswers({
      protocolId: id,
      protocolName: title,
      molecule: molecule,
    });
    navigation.goNext();
  };

  const selectSubProblemTitle =
    data.protocolCategories[0].title === 'Poids'
      ? t`selectsubproblem.title.weight`
      : t`selectsubproblem.title`;

  const protocols = subprotocols?.protocols || [];
  // order for molecules
  const molecules = [MOUNJARO, SAXENDA, WEGOVY];

  // sort protocols by molecule
  const sortedProtocols = [...protocols].sort((a, b) => {
    const aIndex = a.molecule
      ? molecules.indexOf(a.molecule)
      : molecules.length;
    const bIndex = b.molecule
      ? molecules.indexOf(b.molecule)
      : molecules.length;
    return aIndex - bIndex;
  });

  return (
    <OnboardingTemplateScreen title={selectSubProblemTitle}>
      {sortedProtocols.map((subCategory) => (
        <div key={subCategory.title} className="w-full">
          <ProblemItem
            className={`${
              subCategory.id === protocolId ? 'bg-grey-700' : 'bg-grey-800'
            } `}
            onClick={() =>
              onProblemItemClicked(
                subCategory.id,
                subCategory.title,
                subCategory.molecule,
              )
            }
            label={subCategoryTitle(subCategory.molecule)}
          />
          <div className="h-8" />
        </div>
      ))}
    </OnboardingTemplateScreen>
  );
};
