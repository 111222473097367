/* eslint-disable @typescript-eslint/no-explicit-any */ 
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ISO8601Date: any;
  JSON: any;
};

/** Patient motivation text for onboarding */
export type AddMotivationText = {
  /** Patient motivation text */
  motivationText: Scalars['String'];
};

/** Autogenerated input type of AddMotivationText */
export type AddMotivationTextInput = {
  /** Motivation Text Input */
  attributes: AddMotivationText;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of AttachAvatar */
export type AttachAvatarInput = {
  /** Signed blob ID generated via `createDirectUpload` mutation */
  blobId: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  patientId: Scalars['ID'];
};

/** Autogenerated input type of AttachVoice */
export type AttachVoiceInput = {
  /** Signed blob ID generated via `createDirectUpload` mutation */
  blobId: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  enrollmentId: Scalars['ID'];
};

export enum CercleState {
  /** Cercle is in consolidation step */
  Consolidation = 'CONSOLIDATION',
  /** Cercle has been created */
  Created = 'CREATED',
  /** Cercle is closed */
  Finished = 'FINISHED',
  /** Cercle has been planned */
  Planned = 'PLANNED',
  /** Cercle is in progression step */
  Progress = 'PROGRESS'
}

/** Autogenerated input type of ChangePassword */
export type ChangePasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  currentPassword: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};

/** Informations required to submit a check-in */
export type CheckIn = {
  /** Check-in survey answers */
  answers: Scalars['JSON'];
  enrollmentId: Scalars['ID'];
};

/** Autogenerated input type of CheckIn */
export type CheckInInput = {
  /** Inputs to submit check in */
  attributes: CheckIn;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateDirectUpload */
export type CreateDirectUploadInput = {
  attributes: DirectUploadAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** File information required to prepare a direct upload */
export type DirectUploadAttributes = {
  /** File size (bytes) */
  byteSize: Scalars['Int'];
  /** MD5 file checksum as base64 */
  checksum: Scalars['String'];
  /** File content type */
  contentType: Scalars['String'];
  /** Original file name */
  filename: Scalars['String'];
};

/** Autogenerated input type of EnrollInCercle */
export type EnrollInCercleInput = {
  cercleId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  enrollmentId: Scalars['ID'];
};

/** Autogenerated input type of EnrollInNextCercle */
export type EnrollInNextCercleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  enrollmentId: Scalars['ID'];
};

/** Autogenerated input type of EnrollInSoloCercle */
export type EnrollInSoloCercleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  enrollmentId: Scalars['ID'];
};

export enum EnrollmentState {
  /** Enrollment has been canceled */
  Canceled = 'CANCELED',
  /** Enrollment has been created */
  Created = 'CREATED',
  /** Enrollment has failed to renew */
  Interrupted = 'INTERRUPTED',
  /** Enrollment has been paid */
  Started = 'STARTED',
  /**
   * Enrollment is waiting for payment
   * @deprecated No longer used, no more waiting room.
   */
  Waiting = 'WAITING'
}

export enum EnrollmentSubscriptionStatus {
  /** Subscription is active */
  Active = 'ACTIVE',
  /** Subscription is created */
  Created = 'CREATED',
  /** Subscription has been failed */
  Failed = 'FAILED'
}

/** Autogenerated input type of Login */
export type LoginInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
};

export enum MetricGraph {
  /** Graph of type numerical */
  Numerical = 'NUMERICAL',
  /** Graph of type radiogroup */
  Radiogroup = 'RADIOGROUP',
  /** Graph of type text */
  Text = 'TEXT'
}

export enum PatientApplicationState {
  /** Application has beed accepted */
  Accepted = 'ACCEPTED',
  /** Application has beed accepted */
  AutoAccepted = 'AUTO_ACCEPTED',
  /** Application is submitted waiting to be signed */
  Pending = 'PENDING',
  /** Application has been rejected */
  Rejected = 'REJECTED',
  /** Application is signed waiting to be accepted */
  Signed = 'SIGNED'
}

export enum Payment {
  /** External payment */
  External = 'EXTERNAL',
  /** Installment payment */
  Installment = 'INSTALLMENT',
  /** Manual renewal */
  ManualRenewal = 'MANUAL_RENEWAL',
  /** One off payment */
  OneOff = 'ONE_OFF'
}

/** Autogenerated input type of ResetPassword */
export type ResetPasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  resetToken: Scalars['String'];
};

/** Autogenerated input type of SendResetPasswordInstructions */
export type SendResetPasswordInstructionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
};

/** Autogenerated input type of SetupSoloSubscriptionOrder */
export type SetupSoloSubscriptionOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  enrollmentId: Scalars['ID'];
  paymentType: Payment;
};

/** Autogenerated input type of StartFreeTrialInNextCercle */
export type StartFreeTrialInNextCercleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  enrollmentId: Scalars['ID'];
};

export enum StepState {
  /** Step is over */
  Finished = 'FINISHED',
  /** Step is ongoing */
  Ongoing = 'ONGOING',
  /** Step has not started yet */
  Todo = 'TODO'
}

/** Informations required to submit a onboarding */
export type SubmitApplication = {
  /** Patient email */
  email: Scalars['String'];
  /** Patient first name */
  firstName?: InputMaybe<Scalars['String']>;
  /** Patient has accepted to be contacted */
  hasAcceptedToBeContacted: Scalars['Boolean'];
  /** Medical surver answers */
  medicalSurveyAnswers: Scalars['JSON'];
  /** Motivation surver answers */
  motivationSurveyAnswers?: InputMaybe<Scalars['JSON']>;
  /** Patient password */
  password: Scalars['String'];
  /** Patient phone */
  phoneNumber?: InputMaybe<Scalars['String']>;
  /** Protocol for wich to submit application */
  protocolId: Scalars['String'];
};

/** Autogenerated input type of SubmitApplication */
export type SubmitApplicationInput = {
  /** Inputs to submit patient application */
  attributes: SubmitApplication;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export enum SurveyType {
  /** Survey for patient daily check-in */
  CheckIn = 'CHECK_IN',
  /** Survey for patient medical information */
  Medical = 'MEDICAL',
  /** Survey for patient motivation */
  Motivation = 'MOTIVATION'
}

/** Autogenerated input type of UpdateHobbies */
export type UpdateHobbiesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Patient hobbies */
  hobbies: Array<Scalars['String']>;
  /** Enrollment ID */
  id: Scalars['ID'];
};

/** Informations required to update a patient profile */
export type UpdateProfile = {
  closing?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateProfile */
export type UpdateProfileInput = {
  /** Inputs to update patient profile */
  attributes: UpdateProfile;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type GetEnrollmentQueryVariables = Exact<{
  enrollmentId: Scalars['ID'];
}>;


export type GetEnrollmentQuery = { enrollment?: { __typename: 'Enrollment', id: string, status?: EnrollmentState | null, patient?: { __typename: 'Patient', id: string } | null, subscription?: { __typename: 'EnrollmentSubscription', paymentType?: Payment | null, activatedAt?: any | null, id: string, status?: EnrollmentSubscriptionStatus | null, subscriptionPayments?: Array<{ __typename: 'SubscriptionPayment', amount?: string | null, cbLastDigits?: string | null, completedAt?: any | null }> | null, spreeOrder?: { __typename: 'Order', state: string, token: string, total: number, number: string, paymentMethodId: string, paymentMethods: Array<{ __typename: 'PaymentMethod', id: string, name: string }> } | null } | null, availableCercles: Array<{ __typename: 'Cercle', id: string, seatsLeft: number, price: number, plannedStartAt: any, installmentPrice: number }>, spreeOrder?: { __typename: 'Order', state: string, token: string, total: number, number: string, paymentMethodId: string, paymentMethods: Array<{ __typename: 'PaymentMethod', id: string, name: string }> } | null } | null };

export type StartFreeTrialInNextCercleMutationVariables = Exact<{
  input: StartFreeTrialInNextCercleInput;
}>;


export type StartFreeTrialInNextCercleMutation = { startFreeTrialInNextCercle?: { __typename: 'StartFreeTrialInNextCerclePayload', clientMutationId?: string | null, errors?: { __typename: 'BaseError', message?: string | null } | null, enrollment?: { __typename: 'Enrollment', id: string, spreeOrder?: { __typename: 'Order', state: string, token: string, total: number, number: string, paymentMethodId: string } | null, enrolledCercle?: { __typename: 'Cercle', id: string, price: number, installmentPrice: number, seatsLeft: number, plannedStartAt: any } | null } | null } | null };

export type EnrollInCercleMutationVariables = Exact<{
  input: EnrollInCercleInput;
}>;


export type EnrollInCercleMutation = { enrollInCercle?: { __typename: 'EnrollInCerclePayload', clientMutationId?: string | null, errors?: { __typename: 'BaseError', message?: string | null } | null, enrollment?: { __typename: 'Enrollment', id: string, spreeOrder?: { __typename: 'Order', state: string, token: string, total: number, number: string, paymentMethodId: string } | null, enrolledCercle?: { __typename: 'Cercle', id: string, price: number, installmentPrice: number, seatsLeft: number, plannedStartAt: any } | null } | null } | null };

export type EnrollInNextCercleMutationVariables = Exact<{
  input: EnrollInNextCercleInput;
}>;


export type EnrollInNextCercleMutation = { enrollInNextCercle?: { __typename: 'EnrollInNextCerclePayload', clientMutationId?: string | null, errors?: { __typename: 'BaseError', message?: string | null } | null, enrollment?: { __typename: 'Enrollment', id: string, spreeOrder?: { __typename: 'Order', state: string, token: string, total: number, number: string, paymentMethodId: string } | null, enrolledCercle?: { __typename: 'Cercle', id: string, price: number, installmentPrice: number, seatsLeft: number, plannedStartAt: any } | null } | null } | null };

export type EnrollInSoloCercleMutationVariables = Exact<{
  input: EnrollInSoloCercleInput;
}>;


export type EnrollInSoloCercleMutation = { enrollInSoloCercle?: { __typename: 'EnrollInSoloCerclePayload', clientMutationId?: string | null, errors?: { __typename: 'BaseError', message?: string | null } | null, enrollment?: { __typename: 'Enrollment', id: string, spreeOrder?: { __typename: 'Order', state: string, token: string, total: number, number: string, paymentMethodId: string } | null, enrolledCercle?: { __typename: 'Cercle', id: string, price: number, installmentPrice: number, seatsLeft: number, plannedStartAt: any, solo: boolean } | null } | null } | null };

export type SetupSoloSubscriptionOrderMutationVariables = Exact<{
  input: SetupSoloSubscriptionOrderInput;
}>;


export type SetupSoloSubscriptionOrderMutation = { setupSoloSubscriptionOrder?: { __typename: 'SetupSoloSubscriptionOrderPayload', clientMutationId?: string | null, errors?: { __typename: 'BaseError', message?: string | null } | null, enrollment?: { __typename: 'Enrollment', id: string, spreeOrder?: { __typename: 'Order', state: string, token: string, total: number, number: string, paymentMethodId: string, paymentMethods: Array<{ __typename: 'PaymentMethod', id: string, name: string }> } | null, subscription?: { __typename: 'EnrollmentSubscription', paymentType?: Payment | null, status?: EnrollmentSubscriptionStatus | null, spreeOrder?: { __typename: 'Order', state: string, token: string, total: number, number: string, paymentMethodId: string, paymentMethods: Array<{ __typename: 'PaymentMethod', id: string, name: string }> } | null } | null } | null } | null };

export type AcceptCguMutationVariables = Exact<{ [key: string]: never; }>;


export type AcceptCguMutation = { acceptCgu?: { __typename: 'AcceptCguPayload', success: boolean, enrollment?: { __typename: 'Enrollment', id: string } | null } | null };

export type AddMotivationTextMutationVariables = Exact<{
  input: AddMotivationTextInput;
}>;


export type AddMotivationTextMutation = { addMotivationText?: { __typename: 'AddMotivationTextPayload', clientMutationId?: string | null, errors?: { __typename: 'BaseError', message?: string | null } | null } | null };

export type GetWaitingRoomMediaQueryVariables = Exact<{
  protocolId: Scalars['ID'];
}>;


export type GetWaitingRoomMediaQuery = { protocol?: { __typename: 'Protocol', id: string, presentation?: { __typename: 'ProtocolPresentation', id: string, waitingRoomVideo?: { __typename: 'MediaVideo', url: string } | null, waitingRoomTestimonies: Array<{ __typename: 'MediaTestimony', avatar: { __typename: 'MediaImage', url: string, alt: string }, audio: { __typename: 'MediaAudio', url: string } }> } | null } | null };

export type GetMedicalSearchingTooltipQueryVariables = Exact<{
  protocolId: Scalars['ID'];
}>;


export type GetMedicalSearchingTooltipQuery = { protocol?: { __typename: 'Protocol', id: string, presentation?: { __typename: 'ProtocolPresentation', id: string, medicalSearchingTooltip?: string | null } | null } | null };

export type GetMedicalThankYouTooltipQueryVariables = Exact<{
  protocolId: Scalars['ID'];
}>;


export type GetMedicalThankYouTooltipQuery = { protocol?: { __typename: 'Protocol', id: string, presentation?: { __typename: 'ProtocolPresentation', id: string, medicalThankYouTooltip?: string | null } | null } | null };

export type GetProblemsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProblemsQuery = { protocolCategories: Array<{ __typename: 'ProtocolCategory', id: string, title: string, protocols: Array<{ __typename: 'Protocol', id: string, title: string, molecule?: string | null }> }> };

export type GetConsolidationPlanQueryVariables = Exact<{
  protocolId: Scalars['ID'];
}>;


export type GetConsolidationPlanQuery = { protocol?: { __typename: 'Protocol', id: string, presentation?: { __typename: 'ProtocolPresentation', id: string, methodPlanTooltip?: string | null, methodPlanProgressPhaseTitle?: string | null, methodPlanProgressPhaseDescription?: string | null, methodPlanConsolidationPhaseTitle?: string | null, methodPlanConsolidationPhaseDescription?: string | null } | null } | null };

export type GetExpertDescriptionQueryVariables = Exact<{
  protocolId: Scalars['ID'];
}>;


export type GetExpertDescriptionQuery = { protocol?: { __typename: 'Protocol', id: string, presentation?: { __typename: 'ProtocolPresentation', id: string, methodExpertDescription?: string | null, methodExpertTags: Array<string>, methodExpertVideo?: { __typename: 'MediaVideo', name: string, url: string } | null } | null } | null };

export type GetMembersSpeakQueryVariables = Exact<{
  protocolId: Scalars['ID'];
}>;


export type GetMembersSpeakQuery = { protocol?: { __typename: 'Protocol', id: string, presentation?: { __typename: 'ProtocolPresentation', id: string, methodTestimony?: { __typename: 'MediaTestimony', highlight: string, audio: { __typename: 'MediaAudio', url: string }, avatar: { __typename: 'MediaImage', alt: string, url: string } } | null } | null } | null };

export type GetPriceQueryVariables = Exact<{
  protocolId: Scalars['ID'];
}>;


export type GetPriceQuery = { protocol?: { __typename: 'Protocol', id: string, price: number, initialSuccessFee: number, installmentPrice: number, installmentFrequency: number, stepDefinitions?: Array<{ __typename: 'StepDefinition', duration: number }> | null } | null };

export type GetWhatWeDoAndDoNotQueryVariables = Exact<{
  protocolId: Scalars['ID'];
}>;


export type GetWhatWeDoAndDoNotQuery = { protocol?: { __typename: 'Protocol', id: string, presentation?: { __typename: 'ProtocolPresentation', id: string, methodWhatWeDo?: string | null, methodWhatWeDoNot?: string | null } | null } | null };

export type GetProtocolSpecificDataQueryVariables = Exact<{
  protocolId: Scalars['ID'];
}>;


export type GetProtocolSpecificDataQuery = { protocol?: { __typename: 'Protocol', id: string, price: number, initialSuccessFee: number, installmentPrice: number, installmentFrequency: number, surveys?: Array<{ __typename: 'Survey', title: string, payload?: any | null, type: SurveyType }> | null, presentation?: { __typename: 'ProtocolPresentation', id: string, medicalSearchingTooltip?: string | null, medicalThankYouTooltip?: string | null, methodExpertDescription?: string | null, methodExpertTags: Array<string>, methodWhatWeDo?: string | null, methodWhatWeDoNot?: string | null, methodPlanTooltip?: string | null, methodPlanProgressPhaseTitle?: string | null, methodPlanProgressPhaseDescription?: string | null, methodPlanConsolidationPhaseTitle?: string | null, methodPlanConsolidationPhaseDescription?: string | null, methodExpertVideo?: { __typename: 'MediaVideo', name: string, url: string } | null, methodTestimony?: { __typename: 'MediaTestimony', highlight: string, audio: { __typename: 'MediaAudio', url: string }, avatar: { __typename: 'MediaImage', alt: string, url: string } } | null, waitingRoomVideo?: { __typename: 'MediaVideo', url: string } | null, waitingRoomTestimonies: Array<{ __typename: 'MediaTestimony', avatar: { __typename: 'MediaImage', url: string, alt: string }, audio: { __typename: 'MediaAudio', url: string } }> } | null } | null };

export type GetSurveysQueryVariables = Exact<{
  protocolId: Scalars['ID'];
}>;


export type GetSurveysQuery = { protocol?: { __typename: 'Protocol', id: string, surveys?: Array<{ __typename: 'Survey', title: string, payload?: any | null, type: SurveyType }> | null } | null };

export type SubmitApplicationMutationVariables = Exact<{
  input: SubmitApplicationInput;
}>;


export type SubmitApplicationMutation = { submitApplication?: { __typename: 'SubmitApplicationPayload', clientMutationId?: string | null, token?: string | null, errors?: { __typename: 'BaseError', message?: string | null } | null, patient?: { __typename: 'Patient', id: string, email?: string | null, contactKey: string } | null, enrollment?: { __typename: 'Enrollment', id: string } | null } | null };
