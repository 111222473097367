import { useEffect } from 'react';
import { useOnboardingStore } from '../../shared/domain/onboarding.store';
import { filterProtocolCategoriesById } from '../domain/filterProtocols';
import { useGetProblems } from './useGetProblems';

export const usePrefetchProblems = () => {
  const { data, loading, error, refetch } = useGetProblems();
  const { setOnboardingAnswers, protocolCategoryId } = useOnboardingStore(
    (store) => ({
      setOnboardingAnswers: store.setOnboardingAnswers,
      protocolCategoryId: store.protocolCategoryId,
    }),
  );

  useEffect(() => {
    if (data?.protocolCategories.length === 1) {
      const category = data.protocolCategories[0];

      if (category.protocols.length === 1) {
        setOnboardingAnswers({
          protocolCategoryId: category.id,
          protocolId: category.protocols[0].id,
          protocolName: category.protocols[0].title,
        });
        return;
      }

      setOnboardingAnswers({
        protocolCategoryId: category.id,
      });
    }
  }, [data?.protocolCategories, setOnboardingAnswers]);

  const shouldShowSelectProblemScreen = Boolean(
    data?.protocolCategories && data.protocolCategories.length > 1,
  );
  const selectedCategory = data
    ? filterProtocolCategoriesById(data.protocolCategories, protocolCategoryId)
    : null;
  const subprotocols = selectedCategory?.protocols;
  const shouldShowSelectSubProblemScreen = Boolean(
    subprotocols && subprotocols.length > 1,
  );

  return {
    shouldShowSelectProblemScreen,
    shouldShowSelectSubProblemScreen,
    loading,
    error,
    refetch,
  };
};
